import {
    ArrowPathIcon,
    CheckIcon,
    ChevronRightIcon,
    CloudArrowUpIcon,
    Cog6ToothIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ServerIcon,
} from '@heroicons/react/20/solid'
import { BoltIcon, CalendarDaysIcon, UsersIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';
import LandingPageHeader from './components/LandingPageHeader';
import { useState } from 'react';
import { Radio, RadioGroup } from '@headlessui/react';
import HeroMain1 from "./images/HeroMain1.png";
import HeroMain2 from "./images/HeroMain2.png"
import BinanceLogo from "./images/Binance.png";
import StripeLogo from "./images/stripe.svg";
import DukascopyLogo from "./images/Dukascopy.logo.svg";
import PolygonLogo from "./images/polygon.svg";
import TradingViewLogo from "./images/tradingview.png"

const primaryFeatures = [
    {
        name: 'Identify Winning Strategies',
        description:
            'Backtest your strategies at light speed using historical data to evaluate their performance and identify potential flaws.',
        href: '/signup',
        icon: BoltIcon,
    },
    {
        name: 'Optimize Risk Management',
        description:
            'Analyze past trading decisions to identify risk management errors and improve your approach. Continuously assess your risk exposure.',
        href: '/signup',
        icon: UsersIcon,
    },
    {
        name: 'Build Confidence in Your Trading',
        description:
            'Practice to follow your trading plan consistenly, even when it\'s challenging. Strengthen rational decisions making, based on your analysis, not emotions.',
        href: '/signup',
        icon: CalendarDaysIcon,
    },
]
const secondaryFeatures = [
    {
        name: 'Know your goals.',
        description: 'Figure out what you want to achieve and how much risk you\'re comfortable taking.',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'Choose a style.',
        description: 'Decide if you want to trade short-term, medium-term, or long-term.',
        icon: LockClosedIcon,
    },
    {
        name: 'Pick your market.',
        description: 'Choose what you want to trade, like stocks, currencies, commodities or crypto.',
        icon: ArrowPathIcon,
    },
    {
        name: 'Make a plan.',
        description: 'Create a clear plan for when to buy and sell, how much to invest, and how to manage risk.',
        icon: FingerPrintIcon,
    },
    {
        name: 'Backtest your plan.',
        description: 'Try your strategy on past data to see how it would have worked.',
        icon: Cog6ToothIcon,
    },
    {
        name: 'Review your trades.',
        description: 'get deep insights from your data, and visualize what works and what doesn\'t for you.',
        icon: ServerIcon,
    },
]
const stats = [
    { id: 1, name: 'Pairs (adding new daily)', value: '50+' },
    { id: 2, name: 'Years of historical data', value: '10+' },
    { id: 3, name: 'Speed back testing', value: 'x20' },
    { id: 4, name: 'Time frame data', value: '1 second' },
]

const frequencies = [
    { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
    { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]
const tiers = [
    {
        name: 'Freelancer',
        id: 'tier-freelancer',
        href: '/signup',
        price: { monthly: '$15', annually: '$144' },
        description: 'The essentials to provide your best work for clients.',
        features: ['5 products', 'Up to 1,000 subscribers', 'Basic analytics', '48-hour support response time'],
        mostPopular: false,
    },
    {
        name: 'Startup',
        id: 'tier-startup',
        href: '/signup',
        price: { monthly: '$30', annually: '$288' },
        description: 'A plan that scales with your rapidly growing business.',
        features: [
            '25 products',
            'Up to 10,000 subscribers',
            'Advanced analytics',
            '24-hour support response time',
            'Marketing automations',
        ],
        mostPopular: true,
    },
    {
        name: 'Enterprise',
        id: 'tier-enterprise',
        href: '/signup',
        price: { monthly: '$48', annually: '$576' },
        description: 'Dedicated support and infrastructure for your company.',
        features: [
            'Unlimited products',
            'Unlimited subscribers',
            'Advanced analytics',
            '1-hour, dedicated support response time',
            'Marketing automations',
            'Custom reporting tools',
        ],
        mostPopular: false,
    },
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const footerNavigation = {
    solutions: [
        { name: 'Hosting', href: '#' },
        { name: 'Data Services', href: '#' },
        { name: 'Uptime Monitoring', href: '#' },
        { name: 'Enterprise Services', href: '#' },
    ],
    support: [
        { name: 'Pricing', href: '#' },
        { name: 'Documentation', href: '#' },
        { name: 'Guides', href: '#' },
        { name: 'API Reference', href: '#' },
    ],
    company: [
        { name: 'About', href: '#' },
        { name: 'Blog', href: '#' },
        { name: 'Jobs', href: '#' },
        { name: 'Press', href: '#' },
        { name: 'Partners', href: '#' },
    ],
    legal: [
        { name: 'Claim', href: '#' },
        { name: 'Privacy', href: '#' },
        { name: 'Terms', href: '#' },
    ],
    social: [

        {
            name: 'X',
            href: 'https://x.com/lois_code',
            icon: (props: any) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
                </svg>
            ),
        },

    ],
}

export default function Landing() {
    const [frequency, setFrequency] = useState(frequencies[0])

    return (
        <div className="bg-gray-900">
            <main>
                <LandingPageHeader />
                {/* Hero section */}
                <div className="relative isolate overflow-hidden">
                    <svg
                        aria-hidden="true"
                        className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                    >
                        <defs>
                            <pattern
                                x="50%"
                                y={-1}
                                id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                                width={200}
                                height={200}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" width="100%" height="100%" strokeWidth={0} />
                    </svg>
                    <div
                        aria-hidden="true"
                        className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
                    >
                        <div
                            style={{
                                clipPath:
                                    'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                            }}
                            className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
                        />
                    </div>
                    <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-40 lg:flex lg:px-8 lg:pt-40">
                        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
                            {/* <img
                                alt="Your Company"
                                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                                className="h-11"
                            /> */}
                            <div className="mt-24 sm:mt-32 lg:mt-16">
                                <a href="/signup" className="inline-flex space-x-6">
                                    <span className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20">
                                        Latest updates
                                    </span>
                                    <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
                                        <span>Just opened alpha</span>
                                        <ChevronRightIcon aria-hidden="true" className="h-5 w-5 text-gray-500" />
                                    </span>
                                </a>
                            </div>
                            <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
                                Build strategies that trade as backtested
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                Backtest your trading strategies with historical data to identify what works best. Apply your backtesting insights to develop practical steps, and continuously refine your approach for better results.
                            </p>
                            <div className="mt-10 flex items-center gap-x-6">
                                <a
                                    href="/signup"
                                    className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                                >
                                    Get started
                                </a>
                                <a href="/signup" className="text-sm font-semibold leading-6 text-white">
                                    Live demo <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                                <img
                                    alt="App screenshot"
                                    src={HeroMain1}
                                    width={2432}
                                    height={1442}
                                    className="w-[76rem] rounded-xl bg-white/5 shadow-2xl ring-1 ring-white/10"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo cloud */}
                <div className="mx-auto mt-8 max-w-7xl px-6 sm:mt-16 lg:px-8">
                    <h2 className="text-center text-lg font-semibold leading-8 text-white">
                        We partner with the most innovative companies
                    </h2>
                    <div className="mx-auto mt-16 grid max-w-lg grid-cols-4 items-center  gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-4 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">

                        <img
                            alt="Transistor"
                            src={BinanceLogo}
                            width={158}
                            height={48}
                            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                        />
                        <img
                            alt="Reform"
                            src={StripeLogo}
                            width={158}
                            height={48}
                            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                        />
                        <img
                            alt="Statamic"
                            src={TradingViewLogo}
                            width={158}
                            height={48}
                            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                        />
                        <img
                            alt="SavvyCal"
                            src={PolygonLogo}
                            width={158}
                            height={48}
                            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                        />
                    </div>
                </div>

                {/* Feature section */}
                <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-base font-semibold leading-7 text-indigo-400">Continuous improvement</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            Make consistent profits
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            By backtesting your own strategies, you can tailor them to your specific needs and increase your chances of long-term success.
                        </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                            {primaryFeatures.map((feature) => (
                                <div key={feature.name} className="flex flex-col">
                                    <dt className="text-base font-semibold leading-7 text-white">
                                        <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500">
                                            <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                                        </div>
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                                        <p className="flex-auto">{feature.description}</p>
                                        <p className="mt-6">
                                            <a href={feature.href} className="text-sm font-semibold leading-6 text-indigo-400">
                                                Learn more <span aria-hidden="true">→</span>
                                            </a>
                                        </p>
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>

                {/* Feature section */}
                <div className="mt-32 sm:mt-56">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl sm:text-center">
                            <h2 className="text-base font-semibold leading-7 text-indigo-400">Everything you need</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">For entry-level or experienced traders</p>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                Effortlessly backtest your strategies, analyse your performances, replay your  trades, and become a better trader.
                            </p>
                        </div>
                    </div>
                    <div className="relative overflow-hidden pt-16">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <img
                                alt="App screenshot"
                                src={HeroMain2}
                                width={2432}
                                height={1442}
                                className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
                            />
                            <div aria-hidden="true" className="relative">
                                <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]" />
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
                        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                            {secondaryFeatures.map((feature) => (
                                <div key={feature.name} className="relative pl-9">
                                    <dt className="inline font-semibold text-white">
                                        <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-indigo-500" />
                                        {feature.name}
                                    </dt>{' '}
                                    <dd className="inline">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>

                {/* Stats */}
                <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                        <h2 className="text-base font-semibold leading-8 text-indigo-400">Our track record</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            We listen to traders and we build the tools they need
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            Trader-Lab is a powerful tool that helps traders develop, backtest, and refine their trading strategies before risking real money.
                        </p>
                    </div>
                    <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                        {stats.map((stat) => (
                            <div key={stat.id} className="flex flex-col gap-y-3 border-l border-white/10 pl-6">
                                <dt className="text-sm leading-6">{stat.name}</dt>
                                <dd className="order-first text-3xl font-semibold tracking-tight">{stat.value}</dd>
                            </div>
                        ))}
                    </dl>
                </div>

                {/* CTA section */}
                <div className="relative isolate mt-32 px-6 py-32 sm:mt-56 sm:py-40 lg:px-8">
                    <svg
                        aria-hidden="true"
                        className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                    >
                        <defs>
                            <pattern
                                x="50%"
                                y={0}
                                id="1d4240dd-898f-445f-932d-e2872fd12de3"
                                width={200}
                                height={200}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y={0} className="overflow-visible fill-gray-800/20">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)" width="100%" height="100%" strokeWidth={0} />
                    </svg>
                    <div
                        aria-hidden="true"
                        className="absolute inset-x-0 top-10 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
                    >
                        <div
                            style={{
                                clipPath:
                                    'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                            }}
                            className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
                        />
                    </div>
                    <div className="mx-auto max-w-3xl text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            Forge your own path to better trading
                            <br />
                            Start using Trader-Lab today
                        </h2>
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                            Our Alpha is open to everyone for free. No credit card needed.
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a
                                href="/pricing"
                                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >
                                Get started
                            </a>
                            <a href="/pricing" className="text-sm font-semibold leading-6 text-white">
                                Learn more <span aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                </div>
            </main>

            {/* Footer */}
            <footer aria-labelledby="footer-heading" className="relative">
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="mx-auto max-w-7xl px-6 pb-8 pt-4 lg:px-8">
                    <div className="border-t border-white/10 pt-8 md:flex md:items-start md:justify-between">
                        <div className="flex space-x-6 md:order-2">
                            {footerNavigation.social.map((item) => (
                                <a key={item.name} href={item.href} target='_blank' className="text-gray-500 hover:text-gray-400">
                                    <span className="sr-only">{item.name}</span>
                                    <item.icon aria-hidden="true" className="h-6 w-6" />
                                </a>
                            ))}
                        </div>
                        <div className='flex flex-col space-y-4'>
                            <div className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
                                &copy; 2024 Trader-Lab, Inc. All rights reserved.
                            </div>
                            <div className=" text-xs leading-4 max-w-3xl text-justify text-gray-400 md:order-1 md:mt-0">
                                The information provided on this platform is for informational purposes only and does not constitute financial advice. Past performance is not indicative of future results. Investing involves risk, and there is no guarantee of profits. It is essential to conduct thorough research and consider your own financial situation before making any investment decisions.

                                This platform does not provide investment recommendations or advice. Users are solely responsible for their own investment decisions and should consult with a qualified financial advisor if necessary. We only collect your email address and don't share it with third parties.
                            </div>
                        </div>

                    </div>
                </div>
            </footer>
        </div>
    )
}