import { SubscribeBarsCallback, ResolutionString, IChartingLibraryWidget, StudyOrDrawingAddedToChartEventParams, Bar } from "charting_library";
import IReplayManager from "./replay_manager_base";
import IReplayDatafeedAPI from "../api/replay_datafeed_api_base";
import BacktestingManager from "../../backtesting/manager/backtesting_manager";
import BacktestingAPI from "../../backtesting/api/backtesting_api";
import { ReplayBar } from "../models/replay_datafeed_models";

export default class ReplayManager<T extends IReplayDatafeedAPI, U extends BacktestingManager<BacktestingAPI>> implements IReplayManager {
    currentChart?: IChartingLibraryWidget;

    ontick?: SubscribeBarsCallback;
    listenerGuid?: string;
    symbol?: string;
    public virtualResolution?: ResolutionString;
    resolution?: ResolutionString;
    previousResolution?: ResolutionString;
    smallestResolution?: ResolutionString;
    speed?: number;
    public from?: number;
    baseTo?: number;
    lastTick?: number;
    updated: boolean = true;
    lastBar?: Bar;
    resolutionChanged: boolean = false;
    endDate?: number;

    paused: boolean = true;

    timer?: NodeJS.Timer;

    cachedBars: ReplayBar[] = [];
    lastCachedTick: number = 0;
    barIndex: number = 0;

    onResetCacheNeededCallback?: () => void;

    constructor(protected api: T, protected manager: U) { }

    setCurrentChart(chart: IChartingLibraryWidget): void {
        this.currentChart = chart;

        chart.onChartReady(() => {
            chart.subscribe("layout_changed", () => {
                window.location.reload()
            })
            chart.activeChart().onIntervalChanged().subscribe(null, (resolution, timeFrameParameters) => {
                console.log("updated resolution to:", resolution);
                console.log(timeFrameParameters);

                this.paused = true;
                this.previousResolution = this.resolution;
                this.resolution = resolution;

                this.smallestResolution = convertResolutionToSeconds(this.smallestResolution ?? this.previousResolution!) < convertResolutionToSeconds(this.resolution!)
                    ? this.smallestResolution ?? this.previousResolution
                    : this.resolution;

                clearInterval(this.timer!);
                this.timer = undefined;

                this.onResetCacheNeededCallback!();

                console.log("interval cleared");

                const from = this.lastTick! - (convertResolutionToSeconds(resolution) * 200)

                console.log(this.speed);
                console.log(this.resolution);
                console.log(this.lastTick);

                // this.setupReplay(from, this.lastTick!, resolution, this.speed!);

                console.log("replay setup")
            }, false);

            chart.subscribe("onAutoSaveNeeded", () => console.log("auto save needed triggered"))

            chart.subscribe("drawing_event", (sourceId, e) => {
                console.log("drawing id:", sourceId);

                const drawing = chart.activeChart().getShapeById(sourceId);

                console.log(drawing.getAnchoredPosition())
                console.log(drawing.getPoints());
                console.log(drawing.getProperties());
                console.log(chart.activeChart().getPriceToBarRatio());
            });

            chart.subscribe("drawing", (params: StudyOrDrawingAddedToChartEventParams) => {
                console.log("a drawing was created with those params", params);
            })

            chart.subscribe("study", (params: any) => {
                console.log("a study was created with those params:", params)
            });

            chart.subscribe("onTick", (bar) => {
                this.lastBar = bar;
            });
        });

        //chart.subscribe("time_interval", (e) => console.log(e))
    }

    async setupReplay(from: number, to: number, resolution: ResolutionString, speed: number): Promise<void> {
        this.from = from;
        this.baseTo ??= to;
        this.lastTick ??= to;
        this.virtualResolution = resolution;
        this.resolution = resolution;
        this.speed = speed;

        this.barIndex = 0;
        this.cachedBars = [];
        this.lastCachedTick = 0;

        let completed = true;

        this.timer = setInterval(async () => {
            if (this.paused || !completed) {
                return;
            }

            if ((this?.lastTick ?? 0) >= (this?.endDate ?? 0)) {
                return;
            }

            completed = false;

            // check if cache is stored
            if (this.cachedBars.length > 0) {
                // if cached stored check if nextTick Index is greater than upper threshold
                const secondsToAdd = convertResolutionToSeconds(this.virtualResolution!);
                const secondsToSubstract = convertResolutionToSeconds(this.virtualResolution!) * 20;
                const nextTick = this.lastTick! + secondsToAdd;

                if (nextTick > this.lastCachedTick - secondsToSubstract) {
                    const secondsToAdd = convertResolutionToSeconds(this.virtualResolution!) * 100;
                    const nextCacheTick = this.lastCachedTick + secondsToAdd;
                    const previousCachedTick = this.lastCachedTick + convertResolutionToSeconds(this.virtualResolution!);

                    const nextBar = this.cachedBars[this.barIndex];

                    this.ontick!(nextBar);

                    const [bars, done] = await this.api.history(this.symbol!, `${previousCachedTick}`, `${nextCacheTick}`, this.virtualResolution!, 10);

                    if (done && bars.length == 0) {
                        this.lastTick = nextTick;
                        completed = true;
                        return;
                    }

                    this.lastTick = nextTick;
                    this.lastCachedTick = nextCacheTick;

                    this.cachedBars = this.cachedBars.filter((bar) => bar.time / 1000 >= nextTick);
                    this.cachedBars.push(...bars);
                    this.barIndex = 0;
                } else {
                    console.log("over there", this.cachedBars);
                    const nextBar = this.cachedBars[this.barIndex];
                    this.barIndex++;
                    this.lastTick = nextTick;

                    this.ontick!(nextBar);
                }
            } else {
                const secondsToAdd = convertResolutionToSeconds(this.virtualResolution!) * 100;
                const nextCacheTick = this.lastTick! + secondsToAdd;
                const nextTick = this.lastTick! + convertResolutionToSeconds(this.virtualResolution!);

                const [bars, done] = await this.api.history(this.symbol!, `${nextTick}`, `${nextCacheTick}`, this.virtualResolution!, 10);

                if (done && bars.length == 0) {
                    this.lastTick = nextTick;
                    completed = true;
                    return;
                }

                this.lastCachedTick = nextCacheTick;
                this.lastTick = nextTick;

                this.cachedBars = bars;
                this.barIndex = 0;

                const nextBar = bars[this.barIndex];

                this.barIndex++;

                this.ontick!(nextBar);
            }

            completed = true;
        }, this.speed);
    }

    async setupReplayEco(from: number, to: number, resolution: ResolutionString, speed: number): Promise<void> {
        this.from = from;
        this.baseTo ??= to;
        this.lastTick ??= to;
        this.virtualResolution = resolution;
        this.resolution = resolution;
        this.speed = speed;

        let completed = true;

        this.timer = setInterval(async () => {
            if (this.paused || !completed) {
                return;
            }

            completed = false;

            const secondsToAdd = convertResolutionToSeconds(this.virtualResolution!);
            const nextTick = this.lastTick! + secondsToAdd;

            if (nextTick > (this.endDate ?? 0)) return;

            const [bars, done] = await this.api.history(this.symbol!, `${this.lastTick}`, `${nextTick}`, this.virtualResolution!, 10);

            if (done) {
                this.lastTick = nextTick;
                completed = true;
                return;
            }

            if (bars.length > 0) {
                for (let i = 0; i < bars.length; i++) {
                    const bar = bars[i];
                    this.updated = true;

                    this.ontick!(bar)
                }

                this.lastTick = nextTick;
            }

            completed = true;
        }, this.speed);

        // do request to get initial data;
    }

    clearReplay() {
        this.currentChart?.remove();
        this.currentChart = undefined;
        this.ontick = undefined;
        this.listenerGuid = undefined;
        this.symbol = undefined;
        this.virtualResolution = undefined;
        this.resolution = undefined;
        this.previousResolution = undefined;
        this.smallestResolution = undefined;
        this.speed = undefined;
        this.from = undefined;
        this.baseTo = undefined;
        this.lastTick = undefined;
        this.lastBar = undefined;
        this.endDate = undefined;
        clearInterval(this.timer);
        this.timer = undefined;
        this.listenerGuid = undefined;
    }

    stopReplay(listenerGuid: string) {
        if (this.listenerGuid == listenerGuid) {
            clearInterval(this.timer);
            this.paused = true;
            this.timer = undefined;
            this.listenerGuid = undefined;
        }
    }

    updateSymbol(symbol: string): Promise<void> {
        throw new Error("Method not implemented.");
    }

    async updateTimeResolution(resolution: ResolutionString): Promise<void> {
        this.resolution = resolution;
    }

    async updateReplaySpeed(speed: number): Promise<void> {
        this.speed = speed;
        clearInterval(this.timer);

        this.setupReplay(this.from!, this.lastTick!, this.resolution!, this.speed);
    }

    async pauseReplay(): Promise<void> {
        this.paused = true;
    }

    async startReplay(): Promise<void> {
        this.paused = false;
    }

    async revertReplay(): Promise<void> {
        clearInterval(this.timer);
        setTimeout(() => {
            this.onResetCacheNeededCallback!();
            this.paused = true;
            this.lastTick = this.baseTo;
            this.currentChart?.activeChart().resetData();
        }, 300);
    }
}

export const convertResolutionToSeconds = (resolution: ResolutionString): number => {
    switch (resolution) {
        case "1M":
            return 60 * 60 * 24 * 30
        case "1W":
            return 60 * 60 * 24 * 7
        case "D":
            return 60 * 60 * 24;
        case "1D":
            return 60 * 60 * 24;
        case "120":
            return 2 * 60 * 60;
        case "60":
            return 60 * 60;
        case "30":
            return 60 * 30;
        case "15":
            return 60 * 15;
        case "5":
            return 60 * 5;
        case "3":
            return 3 * 60;
        case "1":
            return 60;
        case "15S":
            return 15;
        case "3S":
            return 3;
        case "1S":
            return 1;
        default:
            throw Error(`resolution ${resolution} not supported`);
    }
}

export const computeBar = (bars: Bar[], resolution: ResolutionString): Bar => {
    let bar = new ReplayBar(bars[0].time, bars[0].open, 0, bars[0].low, bars[bars.length - 1].close, 0)

    for (let i = 0; i < bars.length; i++) {
        const b = bars[i];

        console.log("=/=/=/=/=/=/=/")
        console.log(b)
        console.log("=/=/=/=/=/=/=/")

        if (b.high > bar.high) {
            bar.high = b.high;
        }

        if (b.low < bar.low) {
            bar.low = b.low;
        }

        bar.volume = (bar.volume ?? 0) + (b.volume ?? 0);

        console.log("=/=/=/=/=/=/=/")
        console.log(bar)
        console.log("=/=/=/=/=/=/=/")
    }

    return bar;
}